<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-if="isPatientFormActive"
        v-model="isPatientFormActive"
        persistent
        max-width="50%"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Patient Details</span>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="patientForm"
            >
              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="patientDTO.jacketId"
                    label="Jacket ID"
                    hint="Facility Permanent Patient Jacket ID"
                    :rules="[required]"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="patientDTO.bookingId"
                    label="Booking ID"
                    hint="Facility Patient Booking ID"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="patientDTO.firstName"
                    label="First Name"
                    hint="John"
                    :rules="[required]"
                    :readonly="!isEdit"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="patientDTO.lastName"
                    label="Last Name"
                    hint="Doe"
                    :rules="[required]"
                    :readonly="!isEdit"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <name-type-select
                    v-if="isEdit"
                    v-model="patientDTO.nameType"
                    :name-type="patientDTO.nameType"
                    :rules="[required]"
                    label="Name Type"
                    @name-type-select="updateNameType"
                  ></name-type-select>
                  <v-text-field
                    v-else
                    :value="formatItem(patientDTO.nameType)"
                    label="Name Type"
                    hint="BirthName"
                    :readonly="!isEdit"
                    @click="log(patientDTO.nameType)"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  lg="6"
                >
                  <gender-select
                    v-if="isEdit"
                    v-model="patientDTO.gender"
                    :gender="patientDTO.gender"
                    :rules="[required]"
                    label="Gender"
                    @gender-select="updateGender"
                  ></gender-select>
                  <v-text-field
                    v-else
                    :value="formatItem(patientDTO.gender)"
                    label="Gender"
                    :readonly="!isEdit"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <race-select
                    v-if="isEdit"
                    v-model="patientDTO"
                    :race="patientDTO.race"
                    :rules="[required]"
                    label="Race"
                    @race-select="updateRace"
                  ></race-select>
                  <v-text-field
                    v-else
                    :value="formatItem(patientDTO.race)"
                    label="Race"
                    :readonly="!isEdit"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <alert-select
                    v-if="isEdit"
                    v-model="patientDTO"
                    :patient-alerts="patientDTO.patientAlerts"
                    :rules="[required]"
                    label="Alerts"
                    @alert-select="updateAlerts"
                  ></alert-select>
                  <v-text-field
                    v-for="{alert} in patientDTO.patientAlerts"
                    v-else
                    :key="alert"
                    :value="alert.alertName"
                    label="Alerts"
                    :readonly="!isEdit"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  lg="4"
                >
                  <site-pod-select
                    v-if="isEdit"
                    v-model="patientDTO"
                    :rules="[required]"
                    :pod="formatLocation(patientDTO.patientSites, 'pod')"
                    label="Pod"
                    @pod-select="updatePod"
                  ></site-pod-select>
                  <v-text-field
                    v-else
                    :value="formatLocation(patientDTO.patientSites, 'pod').display"
                    label="Pod"
                    :readonly="!isEdit"
                  >
                  </v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  lg="4"
                >
                  <site-cell-select
                    v-if="isEdit"
                    v-model="patientDTO"
                    :rules="[required]"
                    :cell="formatLocation(patientDTO.patientSites, 'cell')"
                    label="cell"
                    @cell-select="updateCell"
                  ></site-cell-select>
                  <v-text-field
                    v-else
                    :value="formatLocation(patientDTO.patientSites, 'cell').display"
                    label="Cell"
                    :readonly="!isEdit"
                  >
                  </v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  lg="4"
                >
                  <site-bed-select
                    v-if="isEdit"
                    v-model="patientDTO"
                    :rules="[required]"
                    :bed="formatLocation(patientDTO.patientSites, 'bed')"
                    label="Bed"
                    @bed-select="updateBed"
                  ></site-bed-select>
                  <v-text-field
                    v-else
                    :value="formatLocation(patientDTO.patientSites, 'bed').display"
                    label="Bed"
                    :readonly="!isEdit"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-menu
                    ref="menuref"
                    v-model="menuDOB"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="patientDTO.birthDate"
                        :prepend-icon="icons.mdiCalendar"
                        label="Birth Date"
                        hint="MM/DD/YYYY"
                        :rules="[required]"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="patientDTO.birthDate"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                      color="primary"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-menu
                    ref="menuref"
                    v-model="menuDOC"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="patientDTO.bookingDate"
                        :prepend-icon="icons.mdiCalendar"
                        label="Booking Date"
                        hint="MM/DD/YYYY"
                        :rules="[required]"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="patientDTO.bookingDate"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                      color="primary"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-menu
                    ref="menuref"
                    v-model="menuDOR"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="patientDTO.releaseDate"
                        :prepend-icon="icons.mdiCalendar"
                        label="Release Date"
                        hint="MM/DD/YYYY"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="patientDTO.releaseDate"
                      :max="new Date().toISOString().substr(0, 10)"
                      min="1950-01-01"
                      color="primary"
                      @change="save"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-switch
                    v-if="isEdit"
                    v-model="patientDTO.isActive"
                    color="primary"
                    :readonly="!isEdit"
                  >
                    <template v-slot:label>
                      {{ patientDTO.isActive ? "Active" : "Inactive" }}
                    </template>
                  </v-switch>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="text-right">
                  <v-btn
                    color="error darken-1"
                    type="reset"
                    text
                    @click="closeForm()"
                  >
                    Close
                  </v-btn>
                  <v-btn
                    color="primary darken-1"
                    text
                    @click="submitForm(patientDTO)"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import store from '@/store'
import { required } from '@core/utils/validation'
import { mdiClose, mdiCalendar } from '@mdi/js'
import { ref, onUnmounted, watch } from '@vue/composition-api'
import patientService from '@/services/PatientService'
import usePatientList from '@/store/usePatientService'
import genderSelect from '@/components/demographics/GenderSelect.vue'
import raceSelect from '@/components/demographics/RaceSelect.vue'
import nameTypeSelect from '@/components/demographics/NameTypeSelect.vue'
import alertSelect from '@/components/patient/AlertSelect.vue'
import sitePodSelect from '@/components/site/SitePodSelect.vue'
import siteCellSelect from '@/components/site/SiteCellSelect.vue'
import siteBedSelect from '@/components/site/SiteBedSelect.vue'

export default {
  components: {
    genderSelect,
    raceSelect,
    nameTypeSelect,
    sitePodSelect,
    siteCellSelect,
    siteBedSelect,
    alertSelect,
  },
  model: {
    prop: 'isPatientFormActive',
    event: 'update:close-form',
  },
  props: {
    isPatientFormActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    patientDTO: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  setup(props, { emit }) {
    const { patientAlerts } = props.patientDTO

    const patientForm = ref(null)
    const { postSitePatient, formatDate } = usePatientList()

    const date = ref(null)
    const menuDOB = ref(false)
    const menuDOC = ref(false)
    const menuDOR = ref(false)
    const picker = ref(null)
    const menuref = ref(null)

    const log = msg => {
      console.log(msg)
    }

    watch(menuDOB, val => {
      // eslint-disable-next-line no-return-assign
      const item = val && setTimeout(() => (picker.value.activePicker = 'YEAR'))
      console.log(item)
    })

    const save = d => {
      menuref.value.save(d)
    }

    const validate = patientDTO => {
      if (patientForm.value.validate(patientDTO)) {
        return true
      }

      return false
    }

    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeForm = () => {
      console.log('Close Form')
      emit('update:close-form', false)
    }

    const PATIENT_APP = 'app-patient'

    // Register module
    if (!store.hasModule(PATIENT_APP)) {
      store.registerModule(PATIENT_APP, patientService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PATIENT_APP)) store.unregisterModule(PATIENT_APP)
    })

    return {
      patientForm,
      validate,
      closeForm,
      refreshParent,
      patientAlerts,

      postSitePatient,
      formatDate,

      required,

      date,
      menuDOB,
      menuDOC,
      menuDOR,
      picker,
      menuref,
      save,
      log,

      icons: {
        mdiClose,
        mdiCalendar,
      },
    }
  },
  created(){
    console.log("created", this.patientDTO)
    if (this.patientDTO.id == undefined) {
      this.patientDTO.isActive = true
      console.log("created2", this.patientDTO)
    }
  },
  methods: {
    submitForm(patientDTO) {
      console.log(patientDTO)
      if (this.validate(patientDTO)) {
        this.postSitePatient(patientDTO)

        this.$watch('loading', () => {
          console.log('Patient Submit Complete', patientDTO)
        })

        this.refreshParent()
        this.closeForm()
      } else {
        console.log('Not Valid')
      }
    },
    updateGender(genderDTO) {
      this.patientDTO.genderId = genderDTO.value.id
    },
    updateRace(raceDTO) {
      this.patientDTO.raceId = raceDTO.value.id
    },
    updateAlerts(alerts) {
      const patientAlerts = []
      alerts.forEach(alert => {
        if (alert !== 'None') {
          patientAlerts.push({
            isActive: true,
            alertId: alert.id,
            alert,
          })
        }
      })

      if (this.patientAlerts !== undefined && this.patientAlerts.length > 0) {
        this.patientAlerts.forEach(patientAlert => {
          if (!patientAlerts.find(pa => pa.alertId === patientAlert.alertId) && patientAlert.alertId !== undefined) {
            patientAlerts.push({
              isActive: false,
              alertId: patientAlert.alert.id,
              alert: patientAlert.alert,
            })
          }

          return patientAlert
        })
      }

      this.patientDTO.patientAlerts = patientAlerts
    },
    updateNameType(nameTypeDTO) {
      console.log(nameTypeDTO)
      this.patientDTO.nameTypeId = nameTypeDTO.value.id
    },
    updatePod(podDTO) {
      console.log(podDTO)
      this.patientDTO.podId = podDTO.value.id
    },
    updateCell(cellDTO) {
      console.log(cellDTO)
      this.patientDTO.cellId = cellDTO.value.id
    },
    updateBed(bedDTO) {
      console.log(bedDTO)
      this.patientDTO.bedId = bedDTO.value.id
    },
    formatItem(item) {
      if (item != null && item !== undefined) {
        console.log(item)

        return item.display
      }

      return 'N/A'
    },
    formatLocation(patientSite, locationType) {
      try {
        if (patientSite[0] != null || patientSite[0] !== undefined) {
          if (locationType === 'pod') {
            return patientSite[0].pod
          }
          if (locationType === 'cell') {
            return patientSite[0].cell
          }
          if (locationType === 'bed') {
            return patientSite[0].bed
          }
        } else if (patientSite === undefined) {
          console.log('Patient Site Undefined', patientSite)

          return 'N/A'
        }

        return 'N/A'
      } catch (error) {
        console.log('Error Formatting Location', error)

        return 'N/A'
      }
    },
  },
}
</script>
