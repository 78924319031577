<template>
  <div id="pod-select">
    <v-combobox
      v-model="selectedPod"
      :items="podList"
      item-text="display"
      outlined
      label="Select Pod"
      clearable
      dense
      @click="log(selectedPod)"
      @change="selectPod(selectedPod)"
    >
    </v-combobox>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import siteService from '@/services/SiteService'
import useSiteService from '@/store/useSiteService'

export default {
  props: {
    pod: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  setup(props, { emit }) {
    const SITE_APP = 'app-site'

    // Register module
    if (!store.hasModule(SITE_APP)) {
      store.registerModule(SITE_APP, siteService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SITE_APP)) {
        store.unregisterModule(SITE_APP)
      }
    })

    const { podList, fetchSitePods } = useSiteService()

    const selectedPod = ref(props.pod.display)

    const log = msg => {
      // console.log(msg)
    }

    const selectPod = () => {
      emit('pod-select', selectedPod)
    }

    return {
      podList,
      selectedPod,
      fetchSitePods,

      required,
      log,

      selectPod,
    }
  },
  async mounted() {
    await this.fetchSitePods()
  },
}
</script>
