<template>
  <div id="cell-select">
    <v-combobox
      v-model="selectedCell"
      :items="cellList"
      item-text="display"
      outlined
      label="Select Cell"
      clearable
      dense
      @click="log(selectedCell)"
      @change="selectCell(selectedCell)"
    >
    </v-combobox>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import siteService from '@/services/SiteService'
import useSiteService from '@/store/useSiteService'

export default {
  props: {
    cell: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  setup(props, { emit }) {
    const SITE_APP = 'app-site'

    // Register module
    if (!store.hasModule(SITE_APP)) {
      store.registerModule(SITE_APP, siteService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SITE_APP)) {
        store.unregisterModule(SITE_APP)
      }
    })

    const { cellList, fetchSiteCells } = useSiteService()

    const selectedCell = ref(props.cell.display)

    const log = msg => {
      //console.log(msg)
    }

    const selectCell = () => {
      emit('cell-select', selectedCell)
    }

    return {
      cellList,
      selectedCell,
      fetchSiteCells,

      required,
      log,

      selectCell,
    }
  },
  mounted() {
    this.fetchSiteCells()
  },
}
</script>
