<template>
  <div id="race-select">
    <v-combobox
      v-model="selectedRace"
      :items="raceList"
      item-text="display"
      outlined
      label="Select Race"
      clearable
      dense
      @click="log(race)"
      @change="selectRace(selectedRace)"
    >
    </v-combobox>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import demographicsService from '@/services/DemographicsService'
import useDemographicsService from '@/store/useDemographicsService'

export default {
  props: {
    race: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const DEMOGRAPHICS_APP = 'app-demographics'

    // Register module
    if (!store.hasModule(DEMOGRAPHICS_APP)) {
      store.registerModule(DEMOGRAPHICS_APP, demographicsService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEMOGRAPHICS_APP)) {
        store.unregisterModule(DEMOGRAPHICS_APP)
      }
    })

    const { raceList, fetchRaces } = useDemographicsService()

    const selectedRace = ref(props.race.display)

    const log = msg => {
      //console.log(msg)
    }

    const selectRace = () => {
      emit('race-select', selectedRace)
    }

    return {
      raceList,
      selectedRace,
      fetchRaces,

      required,
      log,

      selectRace,
    }
  },
  mounted() {
    this.fetchRaces()
  },
}
</script>
