<template>
  <div id="gender-select">
    <v-combobox
      v-model="selectedGender"
      :items="genderList"
      item-text="display"
      outlined
      label="Select Gender"
      clearable
      dense
      @click="log(gender)"
      @change="selectGender(selectedGender)"
    >
    </v-combobox>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import demographicsService from '@/services/DemographicsService'
import useDemographicsService from '@/store/useDemographicsService'

export default {
  props: {
    gender: {
      type: Object,
      default: () => ({ display: 'Male'}),
    },
  },
  setup(props, { emit }) {
    const DEMOGRAPHICS_APP = 'app-demographics'

    // Register module
    if (!store.hasModule(DEMOGRAPHICS_APP)) {
      store.registerModule(DEMOGRAPHICS_APP, demographicsService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEMOGRAPHICS_APP)) {
        store.unregisterModule(DEMOGRAPHICS_APP)
      }
    })

    const { genderList, fetchGenders } = useDemographicsService()

    const selectedGender = ref(props.gender.display)

    const log = msg => {
      // //console.log(msg)
    }

    const selectGender = () => {
      emit('gender-select', selectedGender)
    }

    return {
      genderList,
      selectedGender,
      fetchGenders,

      required,
      log,

      selectGender,
    }
  },
  mounted() {
    this.fetchGenders()

    this.$watch('genderList', () => {
      if (this.gender != null && this.gender !== undefined) {
        this.genderList.forEach(gender => {
          console.log(gender, this.gender)
          if ((gender.display === this.gender.display) || (gender.id === this.gender.id)) {
            this.selectedGender = gender
          }
        })
    }
      this.selectGender()
    })
  },
}
</script>
