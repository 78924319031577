<template>
  <div>
    <!-- dialog -->
    <patient-add-edit
      v-if="isPatientFormActive"
      v-model="isPatientFormActive"
      :is-edit="isEdit"
      :patient-d-t-o="selectedPatient"
      @close-form="updateDialog"
      @refetch-data="refetchPatients"
    ></patient-add-edit>

    <delete-confirmation
      v-model="isDeleteDialogActive"
      :selected-item="selectedItem"
      @delete-confirmation="submitDelete"
      @refetch-data="refetchPatients"
    ></delete-confirmation>

    <deactivate-confirmation
      v-model="isDeactivateDialogActive"
      :selected-item="selectedItem"
      :item-type="itemType"
      @deactivate-confirmation="submitDeactivate"
      @refetch-data="refetchPatients"
    ></deactivate-confirmation>

    <v-col
      cols="12"
    >
      <v-data-iterator
        :items="sitePatientList"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        hide-default-footer
        :search="searchQuery"
      >
        <template v-slot:header>
          <v-toolbar
            color="blue darken-3"
            class="mb-1"
          >
            <div class="d-flex align-center flex-wrap">
              <v-btn
                v-model="isPatientFormActive"
                color="primary"
                @click="updateDialog(null), isEdit = true"
              >
                <v-icon>{{ icons.mdiPlus }}</v-icon>
                <span>Add New Patient</span>
              </v-btn>
            </div>

            <v-spacer></v-spacer>

            <v-btn-toggle
              v-model="statusFilter"
              mandatory
              color="deep-purple accent-3"
              label="Status"
            >
              <v-btn
                large
                depressed
                @click="statusFilter = true"
              >
                Active
              </v-btn>
              <v-btn
                large
                depressed
                @click="statusFilter = false"
              >
                Inactive
              </v-btn>
              <v-btn
                large
                depressed
                @click="statusFilter = null"
              >
                Both
              </v-btn>
            </v-btn-toggle>

            <v-spacer></v-spacer>

            <v-btn-toggle
              v-model="ageFilter"
              mandatory
              color="deep-purple accent-3"
              label="Age"
            >
              <v-btn
                large
                depressed
                @click="ageFilter = 0"
              >
                Adult
              </v-btn>
              <v-btn
                large
                depressed
                @click="ageFilter = 1"
              >
                Juvenile
              </v-btn>
              <v-btn
                large
                depressed
                @click="ageFilter = 1"
              >
                Both
              </v-btn>
            </v-btn-toggle>

            <v-spacer></v-spacer>

            <v-btn-toggle
              v-model="genderFilter"
              mandatory
              color="deep-purple accent-3"
              label="Gender"
            >
              <v-btn
                large
                depressed
                @click="genderFilter = 0"
              >
                All
              </v-btn>
              <v-btn
                large
                depressed
                @click="genderFilter = 1"
              >
                Male
              </v-btn>
              <v-btn
                large
                depressed
                @click="genderFilter = 2"
              >
                Female
              </v-btn>
            </v-btn-toggle>
          </v-toolbar>

          <v-toolbar
            color="blue darken-3"
            class="mb-1"
          >
            <v-text-field
              v-model="searchQuery"
              clearable
              flat
              hide-details
              :prepend-inner-icon="icons.mdiMagnify"
              label="Search"
            ></v-text-field>
            <v-spacer></v-spacer>

            <template v-if="$vuetify.breakpoint.mdAndUp">
              <v-select
                v-model="sortBy"
                flat
                hide-details
                :items="filteredKeys"
                :prepend-inner-icon="icons.mdiMagnify"
                label="Sort by"
              ></v-select>

              <v-spacer></v-spacer>

              <v-btn-toggle
                v-model="sortDesc"
                mandatory
              >
                <v-btn
                  large
                  depressed
                  :value="false"
                >
                  <v-icon>{{ icons.mdiArrowUp }}</v-icon>
                </v-btn>
                <v-btn
                  large
                  depressed
                  :value="true"
                >
                  <v-icon>{{ icons.mdiArrowDown }}</v-icon>
                </v-btn>
              </v-btn-toggle>
            </template>
          </v-toolbar>
        </template>

        <template v-slot:default="{ items }">
          <v-row>
            <v-col
              v-for="patient in items"
              :key="patient.jacketId"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-card
                max-width="100%"
              >
                <v-img
                  src="@/assets/images/avatars/genericMale.png"
                  max-height="300"
                  @click.native="$router.push({ name: 'site-patient-chart', params: { patientId: patient.id } })"
                >
                </v-img>
                <v-card-title class="subheading font-weight-bold text-no-wrap">
                  {{ patient.jacketId + " | " }}
                  <br />
                  {{ patient.displayName }}
                  <br />
                  Pod: {{ patient.patientSites[0].pod.display }}
                  <br />
                  Cell: {{ patient.patientSites[0].cell.display }}
                </v-card-title>
                <v-card-subtitle>
                  <v-row>
                    <v-chip
                      v-for="(alert, index) in patient.patientAlerts"
                      :key="index"
                      outlined
                      color="error"
                      @click="(isDeactivateDialogActive = !isDeactivateDialogActive) && (itemType = 'Alert') && (selectedItem = alert)"
                    >
                      {{ alert.alert.alertName }}
                    </v-chip>
                  </v-row>

                  <v-row>
                    <v-chip
                      v-for="(allergy, index) in patient.patientAllergies"
                      :key="index"
                      outlined
                      color="secondary"
                      @click="(isDeactivateDialogActive = !isDeactivateDialogActive) && (itemType = 'Allergy') && (selectedItem = allergy)"
                    >
                      {{ allergy.allergyDescriptionCode }}
                    </v-chip>
                  </v-row>
                </v-card-subtitle>

                <v-expansion-panels
                  :multiple="multiple"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-card-actions>
                        <router-link
                          :to="{ name: 'site-patient-chart', params: { patientId: patient.id } }"
                        >
                          <v-btn
                            color="primary"
                            text
                          >
                            View Chart
                          </v-btn>
                        </router-link>
                      </v-card-actions>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-list dense>
                        <template v-for="(field, index) in patientFields">
                          <v-list-item
                            :key="index"
                          >
                            <v-list-item-content
                              class="align-end"
                            >
                              <v-list-item-title>
                                {{ field.text }}:
                              </v-list-item-title>

                              <v-list-item-subtitle v-if="field.text !== 'ACTIONS'">
                                {{ patient[field.value] }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle v-else>
                                <v-item-group>
                                  <v-item link>
                                    <v-tooltip bottom>
                                      <template #activator="{ on, attrs }">
                                        <v-btn
                                          text
                                          v-bind="attrs"
                                          x-small
                                          v-on="on"
                                          @click="selectedPatient = patient, isEdit = false, updateDialog(true)"
                                        >
                                          <v-icon
                                            size="20"
                                            class="me-2"
                                          >
                                            {{ icons.mdiFileDocumentOutline }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Patient Details</span>
                                    </v-tooltip>
                                  </v-item>

                                  <v-item link>
                                    <v-tooltip bottom>
                                      <template #activator="{ on, attrs }">
                                        <v-btn
                                          v-bind="attrs"
                                          text
                                          x-small
                                          v-on="on"
                                          @click="selectedPatient = patient, isEdit = true, updateDialog(true)"
                                        >
                                          <v-icon
                                            size="20"
                                            class="me-2"
                                          >
                                            {{ icons.mdiAccountEditOutline }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Edit Patient</span>
                                    </v-tooltip>
                                  </v-item>

                                  <v-item link>
                                    <v-tooltip bottom>
                                      <template #activator="{ on, attrs }">
                                        <v-btn
                                          v-bind="attrs"
                                          text
                                          x-small
                                          v-on="on"
                                          @click="disablePatient(patient)"
                                        >
                                          <v-icon
                                            size="20"
                                            class="me-2"
                                          >
                                            {{ icons.mdiAccountLockOutline }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{ patient.isActive ? 'Disable ' : 'Activate ' }} Patient</span>
                                    </v-tooltip>
                                  </v-item>

                                  <v-item link>
                                    <v-tooltip bottom>
                                      <template #activator="{ on, attrs }">
                                        <v-btn
                                          v-bind="attrs"
                                          text
                                          x-small
                                          v-on="on"
                                          @click="isDeleteDialogActive = !isDeleteDialogActive"
                                        >
                                          <v-icon
                                            size="20"
                                            class="me-2"
                                          >
                                            {{ icons.mdiDeleteOutline }}
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>Delete Patient</span>
                                    </v-tooltip>
                                  </v-item>
                                </v-item-group>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </v-col>
          </v-row>
        </template>

        <template v-slot:footer>
          <v-row
            class="mt-2"
            align="center"
            justify="center"
          >
            <span class="grey--text">Items per page</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  text
                  color="primary"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ itemsPerPage }}
                  <v-icon>{{ icons.mdiChevronDown }}</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer></v-spacer>

            <span
              class="mr-4
              grey--text"
            >
              Page {{ page }} of {{ numberOfPages }}
            </span>
            <v-btn
              fab
              dark
              color="blue darken-3"
              class="mr-1"
              @click="formerPage"
            >
              <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              color="blue darken-3"
              class="ml-1"
              @click="nextPage"
            >
              <v-icon>{{ icons.mdiChevronRight }}</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-iterator>
    </v-col>
  </div>
</template>

<script>
import {
  mdiArrowDown,
  mdiArrowUp,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiMagnify,
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
} from '@mdi/js'

import patientService from '@/services/PatientService'
import usePatientService from '@/store/usePatientService'

import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import DeleteConfirmation from '@/components/validation/DeleteConfirmation.vue'
import DeactivateConfirmation from '@/components/validation/DeactivateConfirmation.vue'

import allergyService from '@/services/AllergyService'
import useAllergyList from '@/store/useAllergyService'
import CommonList from '@/store/CommonService'
import PatientAddEdit from './PatientAddEdit.vue'

export default {
  components: { PatientAddEdit, DeleteConfirmation, DeactivateConfirmation },
  setup() {
    const PATIENT_APP = 'app-patient'

    // Register module
    if (!store.hasModule(PATIENT_APP)) {
      store.registerModule(PATIENT_APP, patientService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PATIENT_APP)) store.unregisterModule(PATIENT_APP)
    })

    const ALLERGY_APP = 'app-allergy'

    // Register module
    if (!store.hasModule(ALLERGY_APP)) {
      store.registerModule(ALLERGY_APP, allergyService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ALLERGY_APP)) {
        store.unregisterModule(ALLERGY_APP)
      }
    })

    const { formatDate } = CommonList()

    const {
      sitePatientList,
      genderFilter,
      ageFilter,
      sortKeys,
      sortBy,
      sortDesc,
      searchQuery,
      loading,
      patientFields,
      statusFilter,

      fetchPatients,
      deletePatient,
      formatLocation,
      postSitePatient,
      postPatientAlert,
    } = usePatientService()

    const { postPatientAllergy } = useAllergyList()

    const isPatientFormActive = ref(false)
    const isDeleteDialogActive = ref(false)
    const isDeactivateDialogActive = ref(false)
    const isEdit = ref(false)
    const selectedPatient = ref()
    const selectedItem = ref()
    const itemType = ref('')

    return {
      sitePatientList,
      genderFilter,
      ageFilter,
      sortKeys,
      sortBy,
      sortDesc,
      searchQuery,
      loading,
      selectedItem,
      patientFields,
      statusFilter,

      isPatientFormActive,
      isDeleteDialogActive,
      isDeactivateDialogActive,
      isEdit,
      selectedPatient,
      formatDate,

      fetchPatients,
      postSitePatient,
      deletePatient,
      formatLocation,
      itemType,

      postPatientAllergy,
      postPatientAlert,
    }
  },

  data() {
    return {
      isCardDetailsVisible: null,
      itemsPerPageArray: [4, 8, 12, 24, 36, 48, 60, 72, 84, 96, 100],
      multiple: true,
      page: 1,
      itemsPerPage: 8,

      icons: {
        mdiChevronUp,
        mdiChevronDown,
        mdiChevronLeft,
        mdiChevronRight,
        mdiMagnify,
        mdiArrowUp,
        mdiArrowDown,
        mdiAccountOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiPlus,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
      },
    }
  },

  computed: {
    numberOfPages() {
      return Math.ceil(this.sitePatientList.length / this.itemsPerPage)
    },
    filteredKeys() {
      return this.patientFields.filter(
        key => key.value !== 'patientAlerts' && key.value !== 'patientAllergies' && key.value !== 'actions',
      )
    },
    getImgUrl(patient) {
      const image = `@/assets/images/patients/${patient}.jpg`

      return image
    },
  },
  methods: {
    Log(msg) {
      console.log(msg)
    },
    selectPatient(item) {
      this.selectedPatient = item
    },
    disablePatient(patient) {
      const sitePatient = patient
      sitePatient.isActive = !patient.isActive
      console.log('Patient', patient)
      this.postSitePatient(sitePatient)
    },
    selectItem(item) {
      this.selectedItem = item
    },
    submitDelete(patientDTO) {
      this.deletePatient(patientDTO)
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number
    },
    refetchPatients() {
      this.fetchPatients()
      this.itemType = ''
    },
    submitDeactivate() {
      console.log('Status Update', this.itemType, this.selectedItem)
      if (this.itemType === 'Allergy') {
        this.selectedItem.isActive = false
        this.postPatientAllergy(this.selectedItem)
      }
      if (this.itemType === 'Alert') {
        this.selectedItem.isActive = false
        this.postPatientAlert(this.selectedItem)
      }
      this.itemType = ''
    },
    updateDialog(status) {
      console.log('Status', status)
      console.log('Dialog', this.isPatientFormActive)
      if (status == null) {
        this.selectedPatient = {}
      }
      this.isPatientFormActive = !this.isPatientFormActive
      console.log('Selected Item', this.selectedItem)
    },
  },
}
</script>
