<template>
  <div id="alert-select">
    <v-combobox
      v-model="selectedAlert"
      :items="alertList"
      item-text="alertName"
      outlined
      label="Select Alert"
      clearable
      :multiple="multiple"
      dense
      @change="selectAlert()"
    >
    </v-combobox>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@core/utils/validation'

import demographicsService from '@/services/DemographicsService'
import useDemographicsService from '@/store/useDemographicsService'

export default {
  props: {
    patientAlerts: {
      type: Array,
      required: false,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const DEMOGRAPHICS_APP = 'app-demographics'

    // Register module
    if (!store.hasModule(DEMOGRAPHICS_APP)) {
      store.registerModule(DEMOGRAPHICS_APP, demographicsService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEMOGRAPHICS_APP)) {
        store.unregisterModule(DEMOGRAPHICS_APP)
      }
    })

    const { alertList, fetchAlerts } = useDemographicsService()

    const selectedAlert = ref([])

    const selectAlert = () => {
      console.log('Emitting Alert', selectedAlert.value)
      emit('alert-select', selectedAlert.value)
    }

    return {
      alertList,
      selectedAlert,
      fetchAlerts,

      required,

      selectAlert,
    }
  },
  mounted() {
    this.fetchAlerts()


    this.$watch('alertList', () => {
      console.log(this.patientAlerts)
      if (this.patientAlerts != null) {
        this.patientAlerts.forEach(patientAlert => {
          this.alertList.forEach(alert => {
            if ((alert.alertName === patientAlert.alert.alertName) || (alert.id === patientAlert.alert.id)) {
              this.selectedAlert.push(alert)
            }
          })

        })
      }

      if (this.selectedAlert.length > 0) {
        this.selectAlert()
      }
    })
  },
}
</script>
