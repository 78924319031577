import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":"25%"},model:{value:(_vm.isDeactivateDialogActive),callback:function ($$v) {_vm.isDeactivateDialogActive=$$v},expression:"isDeactivateDialogActive"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Deactivation Confirmation")])]),(_vm.itemType === 'Alert')?_c(VCardText,[_c('span',[_vm._v(" Are you sure you want to deactivate the "),_c('strong',[_vm._v(_vm._s(_vm.selectedItem.alert.alertName))]),_vm._v(" alert?")])]):_vm._e(),(_vm.itemType === 'Allergy')?_c(VCardText,[_c('span',[_vm._v(" Are you sure you want to deactivate the "),_c('strong',[_vm._v(_vm._s(_vm.selectedItem.allergyDescriptionCode))]),_vm._v(" allergy?")])]):_vm._e(),(_vm.itemType === null)?_c(VCardText,[_c('span',[_vm._v(" Are you sure you want to deactivate the "),_c('strong',[_vm._v(_vm._s(_vm.selectedItem.display))]),_vm._v("?")])]):_vm._e(),_c(VCardActions,[_c(VRow,[_c(VCol,{staticClass:"text-right"},[_c(VBtn,{attrs:{"color":"error darken-1","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Close ")]),_c(VBtn,{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){return _vm.confirmDeactivate()}}},[_vm._v(" Confirm ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }