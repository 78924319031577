<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="isDeactivateDialogActive"
        persistent
        max-width="25%"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">Deactivation Confirmation</span>
          </v-card-title>
          <v-card-text
            v-if="itemType === 'Alert'"
          >
            <span> Are you sure you want to deactivate the <strong>{{ selectedItem.alert.alertName }}</strong> alert?</span>
          </v-card-text>
          <v-card-text
            v-if="itemType === 'Allergy'"
          >
            <span> Are you sure you want to deactivate the <strong>{{ selectedItem.allergyDescriptionCode }}</strong> allergy?</span>
          </v-card-text>
          <v-card-text
            v-if="itemType === null"
          >
            <span> Are you sure you want to deactivate the <strong>{{ selectedItem.display }}</strong>?</span>
          </v-card-text>

          <v-card-actions>
            <v-row>
              <v-col class="text-right">
                <v-btn
                  color="error darken-1"
                  text
                  @click="closeDialog()"
                >
                  Close
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  text
                  @click="confirmDeactivate()"
                >
                  Confirm
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'isDeactivateDialogActive',
    event: 'update:is-deactivate-dialog-active',
  },
  props: {
    isDeactivateDialogActive: {
      type: Boolean,
      required: true,
    },
    selectedItem: {
      type: Object,
      default: () => ({}),
    },
    itemType: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeDialog = () => {
      emit('update:is-deactivate-dialog-active', false)
    }

    const confirmDeactivate = () => {
      emit('deactivate-confirmation', props.selectedItem)
      closeDialog()
      refreshParent()
    }

    return {
      closeDialog,
      confirmDeactivate,
      refreshParent,
    }
  },
}
</script>
